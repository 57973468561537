import React, { useEffect, useState, useRef } from 'react';
import { IScreen } from 'type';
import { CMSLayout, Col, Row, Text, Button, TouchField, Input02, Select01, RatioCol, RatioCol2, UploadFile, useUIState, useRefState, Grid, Input, Select } from 'components';
import { useNavFunc } from 'navigation';
import { useDynamicResponsiveValue } from 'quickly-react';
import { COLOR, SCREEN } from 'const';
import Store from 'store';
import { Image } from 'react-native';
import { Entypo, AntDesign, Feather } from '@expo/vector-icons';

const CopyBox = ({ content, disableCopy = false }) => {
  const [didCopy, setDidCopy] = useState(false);
  const copyToClipboard = str => {
    const el = document.createElement('textarea');
    el.value = str;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
  };

  const copySnippet = () => {
    copyToClipboard(content);
    setDidCopy(true);
    setTimeout(() => {
      setDidCopy(false);
    }, 1000);
  };
  return (
    <Col backgroundColor={COLOR.GREY_LIGHT} round1 p2 mb2>
      <Text color='#800000'>{content}</Text>
      {!disableCopy && (
        <TouchField onPress={copySnippet} width={30} height={30} borderRadius={15} absolute top={5} right={5} middle>
          <Feather name={didCopy ? "check" : "copy"} size={16} color={COLOR.MAIN} />
        </TouchField>
      )}
    </Col>
  )
}

const shopifyCodeForLiquid = `<script>
var productDetail = {{product | json}};
var shopName = "{{shop.name}}";
var shopUrl = "{{shop.secure_url}}";
var bgSettings = {
  color: '#32abe2',
  remove_buttons: 'Add to cart, Buy it now',
};
console.log('productDetail', productDetail);
console.log('bgSettings', bgSettings);
</script>
<script src="https://dev.msc.personify.tech/shopify-editor-terms-and-conditions.js"></script>
<script src="https://dev.msc.personify.tech/shopify-editor-upload-artwork.js"></script>
<script src="https://dev.msc.personify.tech/shopify.js"></script>`;

const bgProductEditorSectionCode = `{% schema %}
{
  "name": "bg-product-editor",
  "settings": [
    {
     "type": "text",
     "id": "color",
     "default": "#32abe2",
     "label": "Brand color"
    },
    {
     "type": "text",
     "id": "remove_buttons",
     "default": "Add to cart, Buy it now",
     "label": "Remove buttons"
    }
  ]
}
{% endschema %}

<script>
var productDetail = {{product | json}};
var shopName = "{{shop.name}}";
var shopUrl = "{{shop.secure_url}}";
var bgSettings = {{ section.settings | json }};
console.log('productDetail', productDetail);
console.log('bgSettings', bgSettings);
</script>
<script src="https://dev.msc.personify.tech/shopify-editor-terms-and-conditions.js"></script>
<script src="https://dev.msc.personify.tech/shopify-editor-upload-artwork.js"></script>
<script src="https://dev.msc.personify.tech/shopify.js"></script>`;

const productJsonSection = `"bg-product-editor": {
  "type": "bg-product-editor",
  "settings": {
    "color": "#32abe2",
    "remove_buttons": "Add to cart, Buy it now"
  }
}`;

const productJsonOrder = `"order": [
  "main",
  "bg-product-editor",
  "other-section..."
]`;

const productJsonWholeFile = `{
  "sections": {
    "bg-product-editor": {
      "type": "bg-product-editor",
      "settings": {
        "color": "#32abe2",
        "remove_buttons": "Add to cart, Buy it now"
      }
    },
    "main": {...},
    "related-products": {...}
  },
  "order": [
    "main",
    "bg-product-editor",
    "related-products"
  ]
}`

const ShopifyCodeSnippet = () => {
  const [themeCodeType, setThemeCodeType] = useState(undefined);

  const themeOptions = [
    { label: 'product-template.liquid or main-product.liquid file', value: 'liquid' },
    { label: 'product.json file', value: 'json' },
  ];

  const renderCustomize = () => {
    return (
      <Col mb2>
        <Text mb1>You can modify the part: <Text bold>bgSettings</Text><Text caption ml0>{themeCodeType?.value === 'json' ? '(Can also do it via Customizer)' : ''}</Text></Text>
        <Text mb0><Text bold>color: </Text> Color of Customize UI. Default to <Text color='#32abe2' bold>#32abe2</Text></Text>
        <Text mb0><Text bold>remove_buttons: </Text> List of buttons that need to be removed. And they will be replaced with Customize button instead. Default to <Text color='#32abe2' bold>Add to cart</Text> and <Text color='#32abe2' bold>Buy it now</Text> buttons</Text>
      </Col>
    )
  }

  return (
    <Col>
      <Text subtitle1 mb1>Shopify code snippet</Text>
      
      <Select01
        options={themeOptions}
        value={themeCodeType}
        onChange={data => setThemeCodeType(data)}
        placeholder='My theme has...'
        mb1
      />
      {themeCodeType?.value === 'liquid' && (
        <>
          <Text caption mb1>* {`(paste at the end of product liquid file)`}</Text>
          <CopyBox content={shopifyCodeForLiquid} />
          {renderCustomize()}
        </>
      )}
      {themeCodeType?.value === 'json' && (
        <>
          <Text caption mb1>* {`(create new file at Sections > bg-product-editor.liquid)`}</Text>
          <CopyBox content={bgProductEditorSectionCode} />
          <Text caption mb1>* {`(open Templates > product.json and copy this code at the first of "sections")`}</Text>
          <CopyBox content={productJsonSection} />
          <Text caption mb1>* {`(then add "bg-product-editor" inside "order"`}</Text>
          <CopyBox content={productJsonOrder} />
          <Text caption mb1>* {`(the file product.json should look like this:`}</Text>
          <CopyBox content={productJsonWholeFile} disableCopy />
          {renderCustomize()}
        </>
      )}
    </Col>
  )
};

export default ShopifyCodeSnippet;
