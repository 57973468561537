import {
  Col,
  Text,
  CMSLayout,
  Row,
  Grid,
  TouchField,
  Button,
  WalletBalance,
  useMobileScreen,
} from "components";
import React, { useState, useEffect, useMemo } from "react";
import { IScreen, TUser } from "type";
import Store from "store";
import { useNavFunc } from "navigation";
import { COLOR, SCREEN } from "const";
import { Tooltip as TooltipComponent } from 'react-tippy';
import { ActivityIndicator, useWindowDimensions } from "react-native";
import { Chart } from "react-google-charts";
import {
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
} from "recharts";
const moment = require("moment");
import { FontAwesome, FontAwesome5, Ionicons, MaterialCommunityIcons } from "@expo/vector-icons";
import TodayData from "./Reports.Today";
import { exportExcel } from "./Reports.exportExcel";
let XLSX;
import "react-datepicker/dist/react-datepicker.css";
import Customer from "./Reports.Customer";
import { usePaymentStore } from "store/Payment.Store";
import { Elements } from "@stripe/react-stripe-js";
import ResellerStatus from "./ResellerStatus";
import { ValHelper } from "helpers";
import AnyTroubleUI from "./components/AnyTroubleUI";

const Reports: IScreen = () => {
  const { width } = useWindowDimensions();

  const { navigate, reset } = useNavFunc();
  const { navigation } = useNavFunc();
  const UserStore = Store.useUserStore();
  const OrderStore = Store.useOrderStore();
  const ShopStore = Store.useShopStore();
  const { user } = UserStore;
  const [listReseller, setListReseller] = useState<Array<TUser>>([]);
  const [chartWidth, setChartWidth] = useState();
  const [todayData, setTodayData] = useState([]);
  const { stripePromise, getStripeConfig, getExistedCard, balance } = usePaymentStore();
  const isMobile = useMobileScreen();

  const resellerId = useMemo(() => {
    if (!user?.role) return "";
    return user.role === "admin" ? "all" : (user.role === "reseller" ? user.id : user.resellerId);
  }, [user]);

  useEffect(() => {
    if (!XLSX) {
      import("xlsx").then((xlsx) => {
        XLSX = xlsx;
      });
    }
  }, []);

  // useEffect(() => {
  //   if (resellerId) {
  //     OrderStore.orderReportAll(resellerId);
  //   }
  // }, [resellerId]);

  useEffect(() => {
    (async () => {
      const { list } = await UserStore.getListResellers(1);
      if (list && list.length > 0) {
        setListReseller(list);
      }
    })();
  }, []);

  useEffect(() => {
    Store.Api.PrintJob.listToday().then((res) => {
      setTodayData(res.data.data?.list || []);
    });
    getStripeConfig();
    getExistedCard();
  }, []);

  const [data, setData] = useState<{
    jobsOverMonth: Array<{ month: string; count: string }>;
    printedJobsOverMonth: Array<{ month: string; count: string }>;
    numberOfPrintJobs: number;
    numberOfPrintedJobs: number;
    listCountClients: Array<{ clientId: string; count: string }>;
    listPrintedCountClients: Array<{ clientId: string; count: string }>;
    customers: Array<{
      client: string;
      email: string;
      first_name?: string;
      last_name?: string;
      products: Array<{
        name: string;
        product_id: number;
        variant_id: number;
        timestamp: string;
        quantity: number;
        order_number: number;
      }>;
    }>;
  }>();

  const getData = async () => {
    const res = await Store.Api.PrintJob.stats();
    console.log(res.data);
    if (res.data.success) setData(res.data.data);
  };

  useEffect(() => {
    getData();
    if (!!user && user.role === 'reseller') {
      ShopStore.resellerHealthCheck();
    }
  }, [user]);

  // const totalPrintJobData = useMemo<
  //   Array<{ job: number; printed: number; name: string }>
  // >(() => {
  //   if (!data || !data.jobsOverMonth) return [];
  //   let res = [];

  //   data.jobsOverMonth.forEach((v) => {
  //     const month = moment(v.month, "YYYY-MM-DD").format("MM/YYYY");
  //     const findPrinted = data.printedJobsOverMonth.find(
  //       (p) => p.month === v.month
  //     );
  //     res.push({
  //       job: Number(v.count),
  //       printed: !findPrinted ? 0 : Number(findPrinted.count),
  //       name: month,
  //     });
  //   });
  //   return res;
  // }, [data]);

  const totalPrintJobData = useMemo(() => {
    const monthString = (v) => {
      const sv = String(v);
      return `${sv[4]}${sv[5]}/${sv.slice(0, 4)}`
    }
    const { orderByMonth, fulfilledByMonth } = OrderStore.reportData;
    const arr = [];
    for (let month in orderByMonth) {
      const obj = {
        order: orderByMonth[month],
        fulfilled: fulfilledByMonth[month] || 0,
        name: monthString(month)
      }
      arr.push(obj);
    }
    for (let month in fulfilledByMonth) {
      if (orderByMonth[month]) continue;
      const obj = {
        order: orderByMonth[month] || 0,
        fulfilled: fulfilledByMonth[month],
        name: monthString(month)
      }
      arr.push(obj);
    }
    return arr;
  }, [OrderStore.reportData]);


  const listClients = useMemo(() => {
    if (!Object.keys(OrderStore.reportData?.countByClient || {}).length) return [];
    const list: Array<{ id: string, client: string; jobs: number; printed: number }> = [];

    Object.keys(OrderStore.reportData.countByClient).forEach(clientId => {
      const v = OrderStore.reportData.countByClient[clientId];
      let client = v.clientName;
      let findClient = listReseller.find((r) => r.id === v.clientId);
      if (!client) {
        if (clientId === "partnet-in-wine") return "Partner In Wine";
        if (clientId === "admin") return "Admin";
        if (!clientId) return "Unknown";
        return !findClient
          ? clientId
          : `${findClient.firstName} ${findClient.lastName}`;
      }
      list.push({
        id: clientId,
        client: v.clientName,
        jobs: v.total,
        printed: v.fulfilled,
      })
    })

    return list.sort((a, b) => (a.jobs > b.jobs ? -1 : 1));
  }, [data, listReseller]);

  const onLayoutSetState = (label, func) => (e) => {
    const value = e.nativeEvent.layout[label];
    func(value);
  };

  return (
    <CMSLayout
      requireAuthen
      breadcrumbs={[{ title: `Summary` }]}
      description={`Summary of orders`}
    >
      <Row marginBottom={0} justifyContent={'space-between'}
        alignItems={'flex-start'}
        {...isMobile ? {
          marginHorizontal: 8,
          marginTop: 10,
        } : {
          m2: true,
        }}
      >
        {/* <Text h3 >Summary</Text> */}
        <Row flexWrap="wrap">
          {resellerId !== "all" && !isMobile &&
            <Button
              text="Helpful Guides"
              borderRadius={20}
              paddingVertical={6}
              paddingHorizontal={12}
              fitContent
              iconLeft={
                <FontAwesome5
                  name="youtube"
                  size={24}
                  color="white"
                  style={{ marginRight: 5 }}
                />
              }
              onPress={() => {}}
              mr1
            />
          }
          {resellerId !== "all" && !user?.otherData?.skipPayment &&
            <Button
              text={isMobile || true ? `Your wallet: ${balance?.currency?.toUpperCase() || ''} ${ValHelper.formatBalance(((balance?.balance * -1) || 0) / 100)}` : "Your wallet"}
              borderRadius={20}
              paddingVertical={6}
              paddingHorizontal={12}
              fitContent
              iconLeft={
                <Ionicons
                  name="wallet-outline"
                  size={24}
                  color="white"
                  style={{ marginRight: 5 }}
                />
              }
              onPress={() => navigation.navigate(SCREEN.Settings, { tabId: 'billing' })}
              mr1
            />
          }
          {!!data && (
            <Button
              text="Export"
              width={120}
              height={30}
              borderRadius={15}
              iconLeft={
                <MaterialCommunityIcons
                  name="microsoft-excel"
                  size={14}
                  color="white"
                  style={{ marginRight: 5 }}
                />
              }
              onPress={() =>
                exportExcel(XLSX, data, totalPrintJobData, listClients)
              }
            />
          )}
        </Row>
      </Row>
      <Col round1
        {...isMobile ? {
          marginHorizontal: 8,
          marginTop: 10,
        } : {
          m2: true,
          bgWhite: true,
          mt1: true,
          p2: true,
          flex1: true,
        }}
      >
        <Grid
          xs="100%"
          md="50%"
          mb2
          alignItems={"flex-start"}
        >
          <Col>
            <Row alignItems={'flex-start'}>
              <Col flex1>
                <TooltipComponent
                  title={'Jobs that need your attention'}
                  position="top"
                >
                  <Col shadow round0 bgWhite flex1 height={120} middle mr1 mb1
                    onPress={() => {
                      reset(SCREEN.ListOrders, { stage: 'pre-production' });
                    }}
                  >
                    <Text textAlign={"center"}>Pending {`\n(Live)`}</Text>
                    <Text fontSize={60}>{OrderStore.reportData?.pendingOrder}</Text>
                  </Col>
                </TooltipComponent>
              </Col>
              <Col flex1>
                <TooltipComponent
                  title={'These jobs have been accepted and are being printed'}
                  position="top"
                >
                  <Col shadow round0 bgWhite flex1 height={120} middle mr1 mb1
                    onPress={() => {
                      reset(SCREEN.ListOrders, { stage: 'in-production' });
                    }}
                  >
                    <Text textAlign={"center"}>In Production {`\n(Live)`}</Text>
                    <Text fontSize={60}>{OrderStore.reportData?.acceptedOrder}</Text>
                  </Col>
                </TooltipComponent>
              </Col>
              <Col flex1>
                <TooltipComponent
                  title={'These jobs have been fulfilled'}
                  position="top"
                >
                  <Col shadow round0 bgWhite flex1 height={120} middle mb1
                    onPress={() => {
                      reset(SCREEN.ListOrders, { stage: 'fulfillment' });
                    }}
                  >
                    <Text textAlign={"center"}>Completed {`\n(In 28 days)`}</Text>
                    <Text fontSize={60}>{OrderStore.reportData?.fulfilledOrderWithIn28d}</Text>
                  </Col>
                </TooltipComponent>
              </Col>
            </Row>
          </Col>
          <Col>
            <Row style={{
              flexWrap: 'wrap',
              alignItems: 'flex-start',
            }}>
              {/* {resellerId !== 'all' && !!stripePromise && !isMobile && (
                <>
                  <Elements stripe={stripePromise}>
                    <WalletBalance mb1 />
                  </Elements>
                </>
              )} */}

              <Row style={{
                minWidth: 280,
                alignItems: 'flex-start',
              }}>
                {resellerId !== 'all' && !!stripePromise && (
                  <ResellerStatus ml1 mb1 />
                )}
              </Row>
              <Row
                // @ts-ignore
                style={{
                  display: user?.role === 'reseller' ? '' : 'none',
                  flex: width > 1450 ? 1 : '',
                  flexWrap: 'wrap',
                  justifyContent: 'flex-end',
                  alignItems: 'flex-start',
                }}
              >
                <AnyTroubleUI />
              </Row>
            </Row>
          </Col>
        </Grid>
        {/* <TodayData data={todayData} listReseller={listReseller} /> */}
        {resellerId === 'all' && (
          <Grid
            xs="100%"
            md="50%"
            alignItems={"flex-start"}
            {...isMobile ? {
              bgWhite: true,
              round0: true,
              marginTop: -16,
            } : {
              marginHorizontal: -5
            }}
          >
            {!isMobile && (
              <Col m0 p0 onLayout={onLayoutSetState("width", setChartWidth)}>
                <Text mb2 bold center>
                  Total Orders
                </Text>
                <LineChart
                  width={chartWidth}
                  height={300}
                  data={totalPrintJobData}
                  margin={{ top: 5, right: 20, bottom: 5, left: 0 }}
                >
                  <Line type="monotone" dataKey="order" stroke="#8884d8" />
                  <Line type="monotone" dataKey="fulfilled" stroke="green" />
                  <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
                  <XAxis dataKey="name" />
                  <YAxis />
                  <Tooltip />
                </LineChart>
              </Col>
            )}
            <Col m0 p0>
              <Text>Total Order: {OrderStore.reportData.totalOrder || 0}</Text>
              <Text>Total values of orders: £{ValHelper.formatMoney(OrderStore.reportData.totalProcessedItemsValue)}</Text>
              <Row p0 m0 mt2>
                <Col flex1>
                  <Text bold>Brand</Text>
                </Col>
                <Col flex1>
                  <Text bold>Number of Jobs</Text>
                </Col>
                <Col flex1>
                  <Text bold>Number of Printed</Text>
                </Col>
              </Row>
              {listClients.map((v, vI) => {
                return (
                  <Row
                    p0
                    m0
                    key={vI}
                    borderTopWidth={1}
                    borderTopColor={COLOR.GREY_LIGHT}
                    backgroundColor={
                      vI % 2 === 0 ? COLOR.GREY_LIGHT : "transparent"
                    }
                  >
                    <Col flex1>
                      <Text bold>{v.client}</Text>
                    </Col>
                    <Col flex1>
                      <Text>{v.jobs}</Text>
                    </Col>
                    <Col flex1>
                      <Text>{v.printed}</Text>
                    </Col>
                  </Row>
                );
              })}
            </Col>
          </Grid>
        )}
        {!isMobile && (
          <Col height={1} backgroundColor={COLOR.BORDER} mv3 />
        )}
        {!isMobile && (
          <Customer customers={data?.customers || []} resellerId={resellerId} listReseller={listReseller} />
        )}
      </Col>
      {isMobile && (
        <Customer customers={data?.customers || []} resellerId={resellerId} listReseller={listReseller} />
      )}
    </CMSLayout>
  );
};

Reports.routeInfo = {
  title: "MSupply - Print Reports",
  path: "/reports",
};

export default Reports;
