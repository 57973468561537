import {
  Col,
  Row,
  Text,
  CMSLayout,
  useUIState,
  ShimmerLoading,
  TouchField,
  Button,
  PrintJobItem,
  SwapIconEffect,
} from "components";
import React, { useState, useEffect, useMemo } from "react";
import { IScreen, TPrintJob, TUser } from "type";
import Store from "store";
import { useNavFunc } from "navigation";
import { SCREEN, COLOR } from "const";
import { ActivityIndicator } from "react-native";
import moment from "moment";
import { SVG } from "assets";
import { SimpleLineIcons, Foundation, Ionicons } from "@expo/vector-icons";
import { saveAs } from "file-saver";
import { TimeHelper } from "helpers";
import { Entypo, Feather } from "@expo/vector-icons";
import { AntDesign } from '@expo/vector-icons';
import * as Animatable from 'react-native-animatable';
import { useDynamicResponsiveValue } from "quickly-react";

const ListResellers: IScreen = () => {
  const { navigate, navigation, route } = useNavFunc();
  // @ts-ignore
  const { query } = route.params || {};
  const rV = useDynamicResponsiveValue();
  const isMobile = rV({ xs: true, lg: false });
  const UserStore = Store.useUserStore();
  const [{ fetching, errorMes, loading: btnLoading }, setUI] = useUIState({
    fetching: false,
  });
  const [pagination, setPagination] = useState({ hasNext: false, total: 1 });
  const [users, setUsers] = useState<Array<TUser>>([]);
  const [fetchingBalances, setFetchingBalances] = useState({});

  const getData = async (p, query = '') => {
    try {
      setUI({ fetching: true, errorMes: "" });
      await TimeHelper.wait(300);
      const { list, hasNext, total, error } = await UserStore.getListResellers(
        p, undefined, decodeURIComponent(query)
      );
      console.log({ list, hasNext, total, error });
      if (error) return setUI({ fetching: true, errorMes: error });
      setPagination({ hasNext, total });
      setUsers(list);
      setUI({ fetching: false, errorMes: "" });
    } catch (err) {
      setUI({ fetching: true, errorMes: String(err) });
    }
  };

  useEffect(() => {
    getData(1, query);
  }, [query]);

  const listWithPriority = useMemo(() => {
    // const manualClients = [
    //   '440478626329',
    //   '665847158329',
    //   '578953627077'
    // ]
    // return [
    //   ...manualClients.map(id => users.find(u => u.id === id)).filter(Boolean),
    //   ...users.filter(u => !manualClients.includes(u.id)),
    // ]
    return users;
  }, [users]);

  const renderJobListMobile = () => {
    if (users.length === 0) {
      return (
        <Col flex1>
          <Text>No reseller found</Text>
        </Col>
      );
    }
    return (
      <Col flex1>
        {listWithPriority.map((val, i) => {
          const colStyle = {
            width: 'calc(50% - 10px)',
            m0: true, p0: true,
          }
          // @ts-ignore
          const balanceText = val.balanceText || '';
          return (
            <Row m0 p0 round0 shadow flexWrap={'wrap'} alignItems={'flex-start'}>
              <Col {...colStyle} width={'100%'}>
                <Text color={COLOR.GREY} fontSize={10} mb0>Name</Text>
                <Text h3 mb1>{val.firstName} {val.lastName}</Text>
              </Col>
              <Col {...colStyle}>
                <Text color={COLOR.GREY} fontSize={10} mb0>Account Name</Text>
                <Text>{val.accountName || '...'}</Text>
              </Col>
              <Col {...colStyle}>
                <Text color={COLOR.GREY} fontSize={10} mb0>Wallet Balance</Text>
                <Row>
                  <Text mr1>{balanceText}</Text>
                  <TouchField width={24} height={24} borderRadius={12} middle mr2
                    onPress={async () => {
                      setFetchingBalances({ ...fetchingBalances, [val.id]: true });
                      const { amount, formatted } = await UserStore.getResellerWalletBalance(val.id);
                      setFetchingBalances({ ...fetchingBalances, [val.id]: false });
                      const newList = users.slice();
                      // @ts-ignore
                      newList[i].balanceText = formatted;
                      newList[i].walletBalance = amount;
                    }}
                  >
                    <Animatable.View
                      animation={fetchingBalances[val.id] ? 'rotate' : null}
                      duration={1000}
                      iterationCount="infinite"
                    >
                      <Ionicons name="reload" size={14} color="black" />
                    </Animatable.View>
                  </TouchField>

                  
                </Row>
              </Col>
              <Col {...colStyle}>
                <Text color={COLOR.GREY} fontSize={10} mb0>Created At</Text>
                <Text>{TimeHelper.formatDay(val.createdAt)}</Text>
              </Col>
              <Row {...colStyle} flexWrap={'wrap'} marginHorizontal={-5}>
                <Col
                  p0 m0 round0 backgroundColor={COLOR.GREY_BG}
                  onPress={() =>
                    navigate(SCREEN.UpsertReseller, { id: val.id, tab: 'info' })
                  }
                >
                  <Text caption>Info</Text>
                </Col>      
                <Col
                  p0 m0 round0 backgroundColor={COLOR.GREY_BG}
                  onPress={() =>
                    navigate(SCREEN.UpsertReseller, { id: val.id, tab: 'transactions' })
                  }
                >
                  <Text caption>Transactions</Text>
                </Col>      
              </Row>
            </Row >
          )
        })}
      </Col >
    );
  }

  const renderJobList = () => {
    if (users.length === 0) {
      return (
        <Col flex1>
          <Text>No reseller found</Text>
        </Col>
      );
    }
    return (
      <Col flex1>
        <Row
          height={50}
          stretch
          borderBottomColor={COLOR.GREY_BG}
          borderBottomWidth={1}
        >
          <Col flex1 m1>
            <Text color={COLOR.GREY}>Name</Text>
          </Col>
          <Col flex1 m1>
            <Text color={COLOR.GREY}>Account Name</Text>
          </Col>
          <Col flex1 m1>
            <Text color={COLOR.GREY}>Wallet Balance</Text>
          </Col>
          <Col flex1 m1>
            <Text color={COLOR.GREY}>Created At</Text>
          </Col>
          <Col flex1 m1></Col>
        </Row>
        <Col>
          {listWithPriority.map((val, i) => {
            // @ts-ignore
            const balanceText = val.balanceText || '';
            return (
              <Row
                height={50}
                stretch
                borderBottomColor={COLOR.GREY_BG}
                borderBottomWidth={1}
                key={val.id}
              >
                <Col flex1 m1 justifyContent={"center"}>
                  <Text mb0>
                    {val.firstName} {val.lastName}
                  </Text>
                  <Text caption>{val.email}</Text>
                </Col>
                <Col flex1 m1 justifyContent={"center"}>
                  <Text>{val.accountName}</Text>
                </Col>
                <Row flex1 m1>

                  <TouchField cirle middle mr2
                    onPress={async () => {
                      setFetchingBalances({ ...fetchingBalances, [val.id]: true });
                      const { amount, formatted } = await UserStore.getResellerWalletBalance(val.id);
                      setFetchingBalances({ ...fetchingBalances, [val.id]: false });
                      const newList = users.slice();
                      // @ts-ignore
                      newList[i].balanceText = formatted;
                      newList[i].walletBalance = amount;
                    }}
                  >
                    <Animatable.View
                      animation={fetchingBalances[val.id] ? 'rotate' : null}
                      duration={1000}
                      iterationCount="infinite"
                    >
                      <Ionicons name="reload" size={14} color="black" />
                    </Animatable.View>
                  </TouchField>

                  <Text>{balanceText}</Text>
                </Row>
                <Col flex1 m1 justifyContent={"center"}>
                  <Text>{TimeHelper.formatDay(val.createdAt)}</Text>
                </Col>
                <Row flex1 m1 justifyContent={"center"} flexWrap={'wrap'}>

                  <TouchField p0 m0
                    onPress={() =>
                      navigate(SCREEN.UpsertReseller, { id: val.id, tab: 'info' })
                    }
                  >
                    <Text caption>Info</Text>
                  </TouchField>
                  <Text> / </Text>
                  <TouchField p0 m0
                    onPress={() =>
                      navigate(SCREEN.UpsertReseller, { id: val.id, tab: 'transactions' })
                    }
                  >
                    <Text caption>Transactions</Text>
                  </TouchField>
                </Row>
              </Row>
            );
          })}
        </Col>
      </Col>
    );
  };

  return (
    <CMSLayout
      requireAuthen
      searchPlaceholder='Search by Name or Email'
      onSearchInput={(keyword) => {
        navigation.setParams({ query: keyword.trim() });
      }}
      onClearSearchInput={() => {
        navigation.setParams({ query: '' });
      }}
      searchInitialValue={query}
    >
      <Row m2 marginBottom={0} justifyContent={"space-between"}>
        <Text h3>Resellers</Text>
        <Button
          isLoading={btnLoading}
          text="Add a new reseller"
          width={200}
          height={40}
          borderRadius={20}
          onPress={async () => {
            // navigate(SCREEN.UpsertReseller, { id: 'new' });
            navigation.reset({
              index: 0,
              routes: [{ name: SCREEN.UpsertReseller, params: { id: "new" } }],
            });
          }}
        />
      </Row>
      <Col flex1 m2 mv1 p2 round1 bgWhite>
        {errorMes ? (
          <Col flex1 middle>
            <Text color="red" subtitle1>
              {errorMes}
            </Text>
          </Col>
        ) : fetching ? (
          <Col>
            <Row height={50} stretch mt2>
              <ShimmerLoading round1 flex={1} m1 />
              <ShimmerLoading round1 flex={1} m1 />
              <ShimmerLoading round1 flex={1} m1 />
              <ShimmerLoading round1 flex={1} m1 />
            </Row>
            <Row height={50} stretch mt2>
              <ShimmerLoading round1 flex={1} m1 />
              <ShimmerLoading round1 flex={1} m1 />
              <ShimmerLoading round1 flex={1} m1 />
              <ShimmerLoading round1 flex={1} m1 />
            </Row>
          </Col>
        ) : (
          isMobile ? renderJobListMobile() : renderJobList()
        )}
      </Col>
    </CMSLayout>
  );
};

ListResellers.routeInfo = {
  title: "Resellers - MSupply",
  path: "/resellers",
};

export default ListResellers;
