import axios from "axios";
import { Col, Row, Text } from "components/base";
import { ButtonOutline } from "components/elements";
import Store, { checkPolotnoData, previewImageOnMask, urltoFile } from 'helpers/EditorSDK';
import { isEmpty } from "lodash";
import React, { forwardRef, useEffect, useImperativeHandle, useMemo, useRef, useState } from "react";
import apiClient, { TDesign, TProduct } from "store/api-client";
// import { MICRO_SERVICES_HOST } from "store/Order.Store";
import { Button as AntButton } from "antd";
import { COLOR, COLORS } from "const";
import { BLEND_SUPPORT_URL, BLEND_URL, getPreviewPTSAction, renderAngle, uploadFileToS3 } from "./utils";

const MICRO_SERVICES_HOST = "https://dev.services.personify.tech";
const editorStore = new Store();
let previewStore = new Store();

interface Props {
  onClose: () => void;
  onDesignSave?: () => void;
  onVariantSave?: (variant: TDesign) => void;
  design?: TDesign;
  product?: TProduct;
  variantDesignId?: string;
}

const tShirtMask = require("../../../assets/img/t-shirt-mask.png");

const EditorIframe = (props: Props, ref: any) => {
  const { design, product, onClose, onDesignSave, onVariantSave, variantDesignId } = props;
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const iframePreviewRef = useRef<HTMLIFrameElement>(null);
  const [previewUrl, setPreviewUrl] = useState('');
  const storeReadyRef = useRef(false);
  const [previewSize, setPreviewSize] = useState(300);
  const [saving, setSaving] = useState(false);
  const [editorStoreReady, setEditorStoreReady] = useState(false);
  const [previewStoreReady, setPreviewStoreReady] = useState(false);
  const [isLoadingPreview, setIsLoadingPreview] = useState(false);
  const productType = product?.data?.productType || "tshirt";

  const onLayoutContainer = (event: any) => {
    const { width, height } = event.nativeEvent.layout;
    setPreviewSize(Math.min(width - 100, height - 100));
  }

  useEffect(() => {
    editorStore.onReady().then(() => {
      if (storeReadyRef.current) return;
      storeReadyRef.current = true;
      setEditorStoreReady(true);
      if (design.data?.canvas && checkPolotnoData(design.data.canvas)) {
        editorStore.loadJSON(design.data.canvas);
      } else if (product?.data?.canvas && checkPolotnoData(product.data.canvas)) {
        editorStore.loadJSON(product.data.canvas);
      }
    });
    return () => {
      setEditorStoreReady(false);
      setPreviewStoreReady(false);
      setSaving(false);
      storeReadyRef.current = false;
      setPreviewUrl('');
    }
  }, []);

  useImperativeHandle(ref, () => ({
    // dispatchEvent: (event: any) => {
    //   iframeRef.current?.contentWindow?.postMessage(event, "*")
    // },
  }), []);

  const onPressPreviewPTSAction = async () => {
    const editorRes: any = await editorStore.generateArtwork(true)
    if (editorRes?.artworkUrl) {
      const artworkUrl = await uploadFileToS3(editorRes?.artworkUrl);
      const res = await getPreviewPTSAction({
        artworkUrl,
        productType,
      })
      return res.data?.data;
    }
    if (editorRes?.artworkUrls?.length) {
      if (productType === "pants") {
        console.log("artworkUrls", editorRes?.artworkUrls)
        if (editorRes?.artworkUrls?.length < 2) return null;
      }
      const artworkUrls = await Promise.all(editorRes?.artworkUrls.map(async (url: string) => {
        const artworkUrl = await uploadFileToS3(url);
        return artworkUrl;
      }))
      const res = await getPreviewPTSAction({
        artworkUrls,
        productType,
      });
      return res.data?.data;
    }
    return null;
  }

  const onPressPreview = async () => {
    if (["backpack", "tshirt", "pants"].includes(productType)) {
      setIsLoadingPreview(true);
      const previewImage = await onPressPreviewPTSAction();
      if (previewImage) {
        setPreviewUrl(`${MICRO_SERVICES_HOST}/products/${productType}/preview?image=${previewImage}`);
        previewStore = new Store();
        setTimeout(() => {
          previewStore.onReady().then(() => {
            setPreviewStoreReady(true);
          });
        }, 1000);
      }
      setIsLoadingPreview(false);
    } else if (productType === "hat") {
      const data: any = await editorStore.generateArtwork()
      // if (data?.previewUrl) {
      //   setPreviewUrl(`${MICRO_SERVICES_HOST}/products/${productType}/preview?image=${data.previewUrl}`);
      // }
      if (data?.artworkUrl) {
        setPreviewUrl(`${MICRO_SERVICES_HOST}/products/${productType}/preview-2d?image=${data.artworkUrl}&blend=${BLEND_URL.hat}&blend_support=${BLEND_SUPPORT_URL}`);
      }
      previewStore = new Store();
      setTimeout(() => {
        previewStore.onReady().then(() => {
          setPreviewStoreReady(true);
        });
      }, 1000);
    } else {
      const data = await editorStore.toDataURL({
        pixelRatio: 1,
        pageId: editorStore.activePage.id,
      }) as string;
      setPreviewUrl(`${MICRO_SERVICES_HOST}/products/${productType}/preview`);
      previewStore = new Store();
      setTimeout(() => {
        previewStore.onReady().then(() => {
          setPreviewStoreReady(true);
          previewStore.setArtworkBase64(data);
        });
      }, 1000);
    }
  }

  const onPressEditor = async () => {
    setPreviewUrl('');
  }

  const onSaveAndClose = async () => {
    setSaving(true);
    try {
      const canvas = editorStore.toJSON();
      let artworkUrl = '';
      if (["tshirt", "backpack", "pants"].includes(productType)) {
        artworkUrl = await onPressPreviewPTSAction();
      } else if (["hat"].includes(productType)) {
        const data: any = await editorStore.generateArtwork(true)
        if (data?.artworkUrl) {
          const blendPreviewUrl = await uploadFileToS3(data.artworkUrl)
          artworkUrl = await renderAngle(blendPreviewUrl, "0");
        }
      } else {
        if (isEmpty(canvas)) return;
        const artworkBase64 = await editorStore.toDataURL({
          pixelRatio: 3,
          pageId: editorStore.activePage.id,
        }) as string;
        const previewBase64 = await previewImageOnMask(tShirtMask, artworkBase64);
        artworkUrl = await uploadFileToS3(previewBase64)
      }

      const res = await apiClient.Api.Design.upsert({
        id: variantDesignId ? (variantDesignId === 'new' ? undefined : variantDesignId) : design?.id,
        data: {
          ...(design.data || {}),
          canvas,
          artworkUrls: [artworkUrl],
        },
        // galleries: (previewImages || []).map(v => v.image),
        image: artworkUrl,
        ...(product && {
          productId: product.id,
          editorHeight: product.editorHeight,
          editorWidth: product.editorWidth,
          width: product.physicalWidth,
          height: product.physicalHeight,
          printAreas: product.printAreas,
          customProduct: !!product.customProduct,
          printOnDemand: !!product.printOnDemand,
          wholeSale: !!product.wholeSale,
        }),
        // @ts-ignore
        parentDesignId: variantDesignId ? design.id : undefined,
      });
      onClose?.();
      if (variantDesignId) {
        onVariantSave?.(res.data.data)
      } else {
        onDesignSave?.()
      }
    } catch (error) {
      alert(error?.message || JSON.stringify(error));
    } finally {
      setSaving(false);
    }
  }

  const previewComp = useMemo(() => {
    if (!previewUrl) return null;
    if (productType === "hat") {
      return (
        <Col absoluteFill backgroundColor="#ccc">
          <iframe
            ref={iframePreviewRef}
            src={previewUrl}
            style={{ width: "100%", height: "100%", border: "none", backgroundColor: "#fff" }}
            onLoad={() => {
              previewStore.init(iframePreviewRef.current);
            }}
          />
        </Col>
      )
    }
    return (
      <Col absoluteFill backgroundColor="#ccc" middle>
        <Col width={previewSize} height={previewSize}>
          <iframe
            ref={iframePreviewRef}
            src={previewUrl}
            style={{ width: "100%", height: "100%", border: "none", backgroundColor: "#fff" }}
            onLoad={() => {
              previewStore.init(iframePreviewRef.current);
            }}
          />
        </Col>
      </Col>
    )
  }, [previewUrl, productType, previewSize])

  return (
    <Col flex1 onLayout={onLayoutContainer}>
      <iframe
        ref={iframeRef}
        src={`${MICRO_SERVICES_HOST}/products/${productType}/editor?bg=${COLOR.MAIN}`}
        style={{ width: "100%", height: "100%", border: "none" }}
        onLoad={() => {
          editorStore.init(iframeRef.current);
        }}
      />
      {previewComp}
      <Row absolute right={12} top={previewUrl ? 12 : 62}>
        {editorStoreReady && !previewUrl && !!editorStore.activePage && (
          <ButtonOutline
            title="Preview"
            onPress={onPressPreview}
            loading={isLoadingPreview}
          />
        )}
        {previewStoreReady && previewUrl && (
          <ButtonOutline
            title="Editor"
            onPress={onPressEditor}
          />
        )}
        {editorStoreReady && (
          <AntButton
            size="large"
            loading={saving}
            type="primary"
            onClick={onSaveAndClose}
            style={{ marginLeft: 8 }}
          >
            <Text fontSize={14} fontWeight="600" colorWhite>Save & Exit</Text>
          </AntButton>
        )}
        <ButtonOutline
          style={{ marginLeft: 8 }}
          title="Close"
          onPress={onClose}
          loading={saving}
        />
      </Row>
    </Col>
  )
}

export default forwardRef(EditorIframe)
