import React, { useEffect, useRef, useState } from "react";
import {
  Col,
  Row,
  TouchField,
  Input,
  Text,
  SwapIconEffect,
  modal,
  UseMemo,
  Button,
} from "components";
import { COLOR, SCREEN } from "const";
import { SVG, ASSETS } from "assets";
import { useDynamicResponsiveValue } from "quickly-react";
import * as Animatable from "react-native-animatable";
import { StyleSheet, ActivityIndicator, Animated, Image } from "react-native";
import { MaterialCommunityIcons, Feather, Ionicons, AntDesign } from "@expo/vector-icons";
import Store from "store";
import { useNavFunc } from "navigation";
import { Alert } from "antd";
import { adminNav, resellerNav } from "./nav";
import SideNav from "./SideNav";
import Drawer from "./Drawer";
import { useExpansionAnim, animStill, AnimatableView } from "./CMSLayout.anim";
import { ValHelper } from "../../../helpers";
import UserTopRightInfo from "./UserTopRightInfo";

export const HEADER_LOGIN_WIDTH = 269 * 0.75;
export const HEADER_LOGIN_HEIGHT = 79 * 0.7;

import Header from "./Header";

const HeaderBar = Row;
const SideBar = Col;
const MainContent = Animated.View;

let didAnimatedOneTime = false;

interface ICMSLayoutProps {
  children: any;
  requireAuthen?: boolean;
  loginRedirectScreen?: string;
  expanded?: boolean;
  searchPlaceholder?: string;
  searchInitialValue?: string;
  searchAutoClear?: boolean;
  onSearchInput?(keyword: string): any;
  onClearSearchInput?(): any;
  breadcrumbs?: Array<{ title: string; screen?: string, params?: any }>;
  description?: string;
}

const CMSMainContent = ({ children }: any) => {
  return (
    <Animatable.View
      animation={didAnimatedOneTime ? animStill : "fadeIn"}
      duration={300}
      delay={300}
      style={{ flex: 1 }}
    >
      {children}
    </Animatable.View>
  );
};

const CMSLayout = ({
  children,
  requireAuthen,
  loginRedirectScreen,
  expanded,
  searchPlaceholder,
  onSearchInput,
  onClearSearchInput,
  searchInitialValue,
  breadcrumbs,
  description,
  searchAutoClear,
}: ICMSLayoutProps) => {
  const { navigate, reset, navigation } = useNavFunc();
  const UserStore = Store.useUserStore();
  const ShopStore = Store.useShopStore();
  const OrderStore = Store.useOrderStore();
  const unpaidOrder = OrderStore.reportData?.unpaidOrderThisYear;

  const rV = useDynamicResponsiveValue(["xs", "md"]);
  const breakpoint = rV({ xs: "xs", md: "md" });
  const [mobileLeftIconIndex, setMobileLeftIconIndex] = useState(0);
  const [searchKeyword, setSearchKeyword] = useState(searchInitialValue || "");
  useEffect(() => {
    setSearchKeyword(searchInitialValue);
  }, [searchInitialValue]);

  useEffect(() => {
    const unsubscribe = navigation.addListener('blur', () => {
      setMobileLeftIconIndex(0);
    });
    return unsubscribe;
  }, []);

  const { shouldDoExpansionAnim, headerAnimated, mainAnimated } =
    useExpansionAnim(expanded);

  useEffect(() => {
    if (requireAuthen) {
      UserStore.onReady().then((u) => {
        if (!u) {
          const params = loginRedirectScreen
            ? { redirect: loginRedirectScreen }
            : undefined;
          navigate(SCREEN.Login, params);
        } else {
          const resellerId = (() => {
            if (!u?.role) return "";
            return u.role === "admin" ? "all" : (u.role === "reseller" ? u.id : u.resellerId);
          })();
          OrderStore.orderReportAll(resellerId);
        }
      });
    }
  }, [requireAuthen, loginRedirectScreen]);

  const onPressLogout = () => {
    // reset(SCREEN.Login);
    Store.Api.User.logout();
    UserStore.set({ user: null, token: "" });
    window.location.pathname = '/login';
  };

  const renderDevEnvNotice = () => {
    if (!window.location.host.includes("dev.bg-production")) return null;
    return (
      <Col absolute bottom={0} left={0} right={0} backgroundColor={"red"}>
        <Text color="white" fontSize={14} fontStyle={"italic"} center>
          DEV envrionment
        </Text>
      </Col>
    )
  }

  const renderSidebarContainer = () => {
    if (breakpoint === "xs") {
      return null;
    }
    if (shouldDoExpansionAnim || !didAnimatedOneTime) {
      const animation = !didAnimatedOneTime
        ? "fadeIn"
        : shouldDoExpansionAnim
          ? expanded
            ? "fadeOutLeftBig"
            : "fadeInLeftBig"
          : undefined;

      return (
        <AnimatableView
          firstRenderAnim={!didAnimatedOneTime ? "fadeIn" : animStill}
          style={styles.sidebarDesktop}
          animation={animation}
          duration={300}
          onAnimationEnd={() => {
            didAnimatedOneTime = true;
          }}
        >
          {renderSidebar()}
          {renderDevEnvNotice()}
        </AnimatableView>
      );
    }
    return (
      <SideBar style={styles.sidebarDesktop}>
        {renderSidebar()}
        {renderDevEnvNotice()}
      </SideBar>
    );
  };

  const renderSidebar = () => {
    const isReseller =
      UserStore.user?.role === "reseller" || UserStore.user?.role === "user";
    let nav = isReseller ? resellerNav : adminNav;
    if (UserStore.isResellerCompleteSetup) {
      // @ts-ignore
      nav = nav.filter((val) => val.title !== 'Get Started');
    }
    if (UserStore.user?.otherData?.skipPayment) {
      // @ts-ignore
      nav = nav.filter((val) => val.title !== 'My Wallet');
    }
    return (
      <>
        {nav.map((val, i) => (
          <SideNav
            key={val.title}
            title={val.title} screen={val.screen} highlights={val.highlights} params={val.params} tooltip={val.tooltip}
            icon={val.icon}
            url={val.url}
            badge={['Job Bag', 'My Orders'].includes(val.title) ? unpaidOrder : undefined}
          />
        ))}
      </>
    );
  };

  const openMobileDrawer = () => {
    setMobileLeftIconIndex(1);
  };

  const closeMobileDrawer = () => {
    setMobileLeftIconIndex(0);
  };

  return requireAuthen && !UserStore.user ? (
    <Col flex1 middle>
      <ActivityIndicator color="black" size="large" />
      <Text mt2>Please wait...</Text>
    </Col>
  ) : (
    <Col flex1 backgroundColor={COLOR.GREY_BG}>
      {/* {renderHeader()} */}
      <Header
        onSearchInput={onSearchInput}
        onClearSearchInput={onClearSearchInput}
        searchInitialValue={searchInitialValue}
        searchPlaceholder={searchPlaceholder}
        onPressLogout={onPressLogout}
        closeMobileDrawer={closeMobileDrawer}
        openMobileDrawer={openMobileDrawer}
        mobileLeftIconIndex={mobileLeftIconIndex}
        headerAnimated={headerAnimated}
        breadcrumbs={breadcrumbs}
        description={description}
        searchAutoClear={searchAutoClear}
      />
      <Row stretch flex1>
        {renderSidebarContainer()}
        <MainContent
          style={
            shouldDoExpansionAnim
              ? {
                position: "absolute",
                left: mainAnimated.left,
                top: mainAnimated.top,
                right: 0,
                bottom: 0,
              }
              : {
                flex: 1,
                height: "100%",
              }
          }
        >
          {ShopStore.needAcceptUpdate && (
            <Alert
              message="New Shopify App version"
              description={(
                <Col>
                  <Text>There is a new update for the MSupply Shopify App. Please accept the update.</Text>
                  <Text marginTop={4} marginBottom={2}>Step 1: Click on <Text textDecorationLine="underline" color={COLOR.BLUE} onPress={() => window.open("https://apps.shopify.com/bottled-goose-print-on-demand", "_blank")}>this link</Text> to open Shopify App</Text>
                  <Text marginBottom={2}>Step 2: Click Open button</Text>
                  <Text marginBottom={2}>Step 3: Accept the update in Shopify admin page</Text>
                  <Text marginTop={4} fontSize={12} fontStyle="italic">
                    Note: You don't need to uninstall the MSupply App
                  </Text>
                </Col>
              )}
              banner
              closable
            />
          )}
          <CMSMainContent>{children}</CMSMainContent>
        </MainContent>
      </Row>
      <Drawer navigate={navigate} show={mobileLeftIconIndex === 1} breadcrumbs={breadcrumbs} description={description} />
    </Col>
  );
};

const styles = StyleSheet.create({
  sidebarDesktop: {
    width: HEADER_LOGIN_WIDTH,
    height: "100%",
    backgroundColor: "white",
    paddingTop: 45,
    // @ts-ignore
    overflowY: "auto",
  },
});

export default CMSLayout;
